
.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: beige;
  font-family: 'Nanum Gothic', sans-serif;
}

.App-title {
  font-size: 5vh;
  font-weight: 600;
  font-family: 'Nanum Gothic', sans-serif;
}

.App-header {
  background-image: url("./assets/images/herfstblik_resized.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  max-height:100%;
  max-width:100%;
  vertical-align:middle;
  text-align: center;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.Beschrijving-paragraaf {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin: 10em 0 10em 0 !important;
}

.Beschikbaarheid-tabel {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.Beschikbaarheid-cel {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 3px;
}

.Beschikbaarheid-paragraaf {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5em 0 5em 0 !important;
}

.kenmerken-paragraaf {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5em 0 5em 0 !important;
}

.route {
  height: 100vh;
  margin: 5em 0 5em 0 !important;
}

.Contact-paragraaf {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 5em 5em 5em 5em !important;
}

.pin {
  width: 45px;
  height: 45px;
  border-radius: 50% 50% 50% 0;
  background: red;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
}

@media (min-width: 768px) {
  .App-header {
    min-height: 100vh;
  }
  .route {
    width: 150vh;
  }
}