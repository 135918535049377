body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: beige;
  font-family: 'Nanum Gothic', sans-serif;
}

.App-title {
  font-size: 5vh;
  font-weight: 600;
  font-family: 'Nanum Gothic', sans-serif;
}

.App-header {
  background-image: url(/static/media/herfstblik_resized.e430f122.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  max-height:100%;
  max-width:100%;
  vertical-align:middle;
  text-align: center;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.Beschrijving-paragraaf {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin: 10em 0 10em 0 !important;
}

.Beschikbaarheid-tabel {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.Beschikbaarheid-cel {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 3px;
}

.Beschikbaarheid-paragraaf {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5em 0 5em 0 !important;
}

.kenmerken-paragraaf {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5em 0 5em 0 !important;
}

.route {
  height: 100vh;
  margin: 5em 0 5em 0 !important;
}

.Contact-paragraaf {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 5em 5em 5em 5em !important;
}

.pin {
  width: 45px;
  height: 45px;
  border-radius: 50% 50% 50% 0;
  background: red;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

@media (min-width: 768px) {
  .App-header {
    min-height: 100vh;
  }
  .route {
    width: 150vh;
  }
}
